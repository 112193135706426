import React from 'react';
import type { StandardBannerProps } from '@noths/polaris-client-ribbons-design-system';
import { StandardBanner } from '@noths/polaris-client-ribbons-design-system';

import { SectionContainer } from 'src/components/molecules/SectionContainer/SectionContainer';
import { trackLinkClick } from 'src/tracking/common/banner';
import { trackImpression } from 'src/tracking/common/trackImpression';
import type { TrackingContext } from 'src/types/TrackingContext';
import * as styles from './StandardBannerSection.style';

export interface StandardBannerSectionProps extends StandardBannerProps {
  trackingPosition?: number;
  trackingTitle: string;
}

export const StandardBannerSection = ({
  linkHref,
  trackingPosition,
  trackingTitle,
  ...restOfProps
}: StandardBannerSectionProps) => {
  const trackingContext: TrackingContext = {
    trackingPosition,
    trackingTitle,
    trackingContentType: 'Standard banner',
  };

  return (
    <SectionContainer maxWidth="full" wrapperStyles={styles.wrapper}>
      <StandardBanner
        imageLinkHref={linkHref}
        linkHref={linkHref}
        onImageLinkClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
          trackLinkClick(e, trackingContext, 'Image')
        }
        onLinkClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
          trackLinkClick(e, trackingContext, 'Link')
        }
        onVisible={() => trackImpression(trackingContext)}
        {...restOfProps}
      />
    </SectionContainer>
  );
};

export type { StandardBannerProps };
